import PortableTextPlus from "@/components/portable-text-plus";
import SegmentContainer from "@/components/segments/segment-container";
import Badge from "@/components/badge";
import classNames from "classnames";

export default function ImageCaptionHoverTextListRows({ segment, ...props }) {
  return (
    <SegmentContainer
      className={classNames(props.paddingClasses)}
      style={{ ...props.bgImageStyle, ...props.bgColorStyle }}
    >
      {segment.heading && (
        <div className="mb-8 lg:mb-12 leading-snug font-semibold text-xl md:text-3xl">
          <PortableTextPlus value={segment.heading} />
        </div>
      )}
      <div
        className={classNames(
          "grid auto-rows-fr justify-items-center",
          props.widthClasses,
          props.gridColsClasses[segment.numBlocksInRow] ||
            props.gridColsClasses["3"]
        )}
      >
        {segment.blocks.map((block, index) => {
          return (
            <div
              className={classNames(
                "flex gap-x-4 px-6 sm:p-0 w-[100vw]",
                index % 2 ? "flex-row-reverse" : "flex-row ",
                "sm:grid sm:grid-cols-1 sm:w-full",
                "text-center group md:px-2 lg:px-4"
              )}
              key={`${index}-${block.heading}`}
            >
              <div className="grid sm:grid-rows-[2fr_1fr] flex-1">
                {block.icon && (
                  <div className="relative my-auto">
                    <div
                      className={classNames(
                        "sm:p-6 group-hover:sm:opacity-0",
                        "transition duration-200 ease-in-out"
                      )}
                    >
                      <PortableTextPlus value={block.icon} />
                    </div>
                    {block.content && (
                      <div
                        className={classNames(
                          "absolute hidden sm:flex flex-col justify-center w-full h-full inset-0",
                          "text-center text-dark-purple text-sm lg:text-base lg:leading-snug",
                          "font-medium opacity-0 group-hover:opacity-100",
                          "transition duration-200 ease-in-out"
                        )}
                      >
                        <PortableTextPlus value={block.content} />
                      </div>
                    )}
                  </div>
                )}
                <div className="mb-2">
                  {block.heading && (
                    <div
                      className={classNames(
                        "font-semibold leading-snug lg:leading-snug",
                        props.headingSizeClasses[block.headingSize] ||
                          "text-base lg:text-lg"
                      )}
                    >
                      <PortableTextPlus value={block.heading} />
                    </div>
                  )}
                  {block.badges && (
                    <div className="mt-0.5 space-x-2">
                      {block.badges.map((badge) => (
                        <Badge key={badge.title} {...badge} />
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {block.content && (
                <div
                  className={classNames(
                    "sm:hidden flex-1 flex items-center",
                    "text-center text-dark-purple text-sm font-medium"
                  )}
                >
                  <PortableTextPlus value={block.content} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </SegmentContainer>
  );
}
